<template>
  <div>
    <el-row>
      <el-col :span="3">

        <div class="tree-box">
          <div v-for="(item,index) in dataTree" :key="index" class="region-label" @click="handleNodeClick(item)">
            {{item.regionName}}
          </div>
        </div>
      </el-col>
      <el-col :span="21">
        <div>
          <!-- <el-button type="primary" size="mini" style="margin:15px 15px 0px 0px;" icon="el-icon-plus" @click.native="isAddDialog = true;editType = 0">新增城市区积分配置</el-button> -->
          <!-- <el-input style="width:180px;margin:15px 15px 0px 0px;" size="mini" placeholder="请输入名称搜索" v-model="search"></el-input>
          <el-button style="margin:15px 15px 0px 0px;" type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button> -->
        </div>
        <el-table stripe :data="list" style="max-width: 100%;margin-top:25px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);height:calc(100vh - 200px);overflow:auto">
          <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
          <el-table-column prop="province" align="center" label="省区"></el-table-column>
          <el-table-column prop="city" align="center" label="城市"></el-table-column>
          <el-table-column align="center" label="供应商">
            <template slot-scope="scope">
              {{scope.row.type == 1 ?'鹤大师':'车多'}}
            </template>
          </el-table-column>
          <el-table-column prop="isRefuelVouchers" align="center" label="有无加油券" width="90">
            <template slot-scope="scope">
              <el-tag size="mini" :type="scope.row.isRefuelVouchers  == 1 ? 'primary' : 'warning'" disable-transitions>{{scope.row.isRefuelVouchers == 1 ?'有':'无'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="standardWashCar" align="center" label="标准洗车价格" width="100"></el-table-column>
          <el-table-column prop="delicateWashCar" align="center" label="精致洗车价格"></el-table-column>
          <el-table-column prop="allUpkeep" align="center" label="全合成保养价格"></el-table-column>
          <el-table-column prop="halfUpkeep" align="center" label="半合成保养价格"></el-table-column>
          <el-table-column prop="mineralOilUpkeep" align="center" label="矿物油保养价格"></el-table-column>
          <el-table-column align="center" label="操作" width="70">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-setting" @click="editDetail(scope.row,2)">设置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog :destroy-on-close="true" :title="isAddDialogTitle[Number(editType)]" :visible.sync="isAddDialog" :lock-scroll="false" :close-on-click-modal="false" width="740px">
      <Add v-if="isAddDialog" :isAddDialog.sync="isAddDialog" :editItem.sync="editItem" :editType.sync="editType"></Add>
    </el-dialog>

  </div>

</template>
<script>
import { getProvincesList, getCityConfigList } from '@/api/setting';
import Add from './components/add.vue'
export default {
  name: 'outlets',
  components: {
    Add
  },
  data() {
    return {
      // tree
      dataTree: [],
      defaultProps: {
        children: 'children',
        label: 'regionName'
      },

      //add Dialog
      isAddDialog: false,
      isDisable: '',           //状态
      isAddDialogTitle: ['新增城市区积分配置', '城市区积分配置', '设置城市区积分配置'],
      editItem: '',              //edit item
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加

      search: '', //搜索

      list: [
      ],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  watch: {
    isAddDialog(n, o) {
      !n && this.getList();
    },
  },
  mounted() {
    this.getTreeList();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    handleNodeClick(data) {
      this.regionCode = data.regionCode
      this.getList();
    },
    getTreeList() {
      let that = this;

      getProvincesList()
        .then((response) => {
          if (response.code == 200) {
            that.dataTree = response.data;
            this.getList();
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
    getList() {
      let that = this;

      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      param['regionCode'] = this.regionCode ? this.regionCode : this.dataTree[0].regionCode;

      // 根据search 输入查询
      if (this.search) {
        param['search'] = this.search;
      }
      getCityConfigList(param)
        .then((response) => {
          if (response.code == 200) {
            that.total = response.data.total;
            that.list = response.data.records;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
    clearSearch() {
      this.search = '';

    },
    // type == 1 查看详情 type == 2 编辑
    editDetail(item, type) {
      this.editType = type;
      this.editItem = item;
      this.isAddDialog = true;
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";

.tree-box {
  margin: 20px 5px 0px 0px;
  height: calc(100vh - 160px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 5px 10px 0px;
  min-width: 100px;
}
.region-label {
  font-size: 12px;
  text-align: left;
  padding: 4px 0px 4px 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.region-label:hover {
  background-color: #f1f1f1;
}
</style>