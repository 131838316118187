<template>
  <el-form ref="form" :model="form" label-width="200px">
    <el-form-item label="地区：">
      <div class="content-text">{{editItem.province + '-' + editItem.city}}</div>
    </el-form-item>

    <el-form-item label="供应商：">
      <el-select v-model="form.type" :disabled="!editShow" placeholder="请选择供应商" style="width:250px">
        <el-option v-for="(item,i) in options" :key="i" :label="item" :value="i"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="标准洗车价格：">
      <el-input style="width:50%;margin-right:10px;" placeholder="请输入价格" v-model.number="form.standardWashCar" type="number" :disabled="!form.isStandard" maxlength="10" step="1" v-if="editShow"></el-input>
      <el-switch v-model="form.isStandard" :disabled="!editShow"></el-switch>
      <div class="content-text" v-if="!editShow">{{form.standardWashCar}}</div>
    </el-form-item>
    <el-form-item label="精致洗车价格：">
      <el-input style="width:50%;margin-right:10px;" placeholder="请输入价格" v-model.number="form.delicateWashCar" type="number" :disabled="!form.isDelicate" maxlength="10" step="1" v-if="editShow"></el-input>
      <el-switch v-model="form.isDelicate" :disabled="!editShow"></el-switch>
      <div class="content-text" v-if="!editShow">{{form.delicateWashCar}}</div>
    </el-form-item>

    <el-form-item label="有无加油券：">
      <el-radio-group v-model="form.isRefuelVouchers" :disabled="!editShow">
        <el-radio :label="1">有</el-radio>
        <el-radio :label="0">无</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="全合成保养：">
      <el-input style="width:50%;margin-right:10px;" placeholder="请输入价格" v-model.number="form.allUpkeep" type="number" :disabled="!form.isAllUpkeep" maxlength="10" step="1" v-if="editShow"></el-input>
      <el-switch v-model="form.isAllUpkeep" :disabled="!editShow"></el-switch>
      <div class="content-text" v-if="!editShow">{{form.allUpkeep}}</div>
    </el-form-item>
    <el-form-item label="半合成保养：">
      <el-input style="width:50%;margin-right:10px;" placeholder="请输入价格" v-model.number="form.halfUpkeep" type="number" :disabled="!form.isHalfUpkeep" maxlength="10" step="1" v-if="editShow"></el-input>
      <el-switch v-model="form.isHalfUpkeep" :disabled="!editShow"></el-switch>
      <div class="content-text" v-if="!editShow">{{form.halfUpkeep}}</div>
    </el-form-item>
    <el-form-item label="矿物油保养：">
      <el-input style="width:50%;margin-right:10px;" placeholder="请输入价格" v-model.number="form.mineralOilUpkeep" type="number" :disabled="!form.isMineralOil" maxlength="10" step="1" v-if="editShow"></el-input>
      <el-switch v-model="form.isMineralOil" :disabled="!editShow"></el-switch>
      <div class="content-text" v-if="!editShow">{{form.mineralOilUpkeep}}</div>
    </el-form-item>

    <div>
      <el-button type="primary" v-debounce="onSubmit" v-if="!editType || editType == 2">{{editType == 2?'立即保存':'立即创建'}}</el-button>
      <el-button @click="dialogClose">关闭窗口</el-button>
    </div>
  </el-form>
</template>
<script>
import { addOrUpdateCityConfig, getCityConfigInfo } from '@/api/setting';
export default {
  props: ['isAddDialog', 'editItem', 'editType'],
  data() {
    return {
      form: {
        iid: '',             //  iid
        regionId: '',        // 地区表主键id
        allUpkeep: '',        // 全合成保养价格
        type: 0,
        isAllUpkeep: false,      // 是否有全合成保养
        isHalfUpkeep: false,     // 是否有半合成保养(0.否,1.是)
        isMineralOil: false,     //  是否有矿物油保养(0.否,1.是)
        isDelicate: false,       //  是否有精致洗车(0.否,1.是)
        isStandard: false,       //  是否有标准洗车(0.否,1.是)
        isRefuelVouchers: 0, // 有无加油券(1.有,0.无)

        delicateWashCar: '',  // 精致洗车价格
        standardWashCar: '',  // 标准洗车价格
        mineralOilUpkeep: '', //  矿物油保养价格
        halfUpkeep: '',       //  半合成保养价格
      },
      options: ['车多', '鹤大师']

    }
  },
  computed: {
    editShow() {
      return this.editType == 2 || !this.editType
    }
  },
  mounted() {

    this.editType && this.editItem.iid && this.getDateil();

  },
  methods: {
    changeCheck(checkKey) {
      // 去中文正则
      this.form[checkKey] = this.form[checkKey].replace(/[^\w\.\/]/ig, '');
    },
    getDateil() {
      let that = this;
      getCityConfigInfo(this.editItem.iid)
        .then((response) => {

          if (response.code == 200) {
            let form = response.data;

            form.isAllUpkeep = form.isAllUpkeep ? true : false;
            form.isHalfUpkeep = form.isHalfUpkeep ? true : false;
            form.isMineralOil = form.isMineralOil ? true : false;
            form.isDelicate = form.isDelicate ? true : false;
            form.isStandard = form.isStandard ? true : false;
            that.form = form
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit() {

      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));

      // if(valid){
      //   this.$message.error({
      //       title: '错误',
      //       message: '请检查输入重试',
      //       duration: 1500
      //   });
      //   return false;
      // }
      form.regionId = this.editItem.id
      form.isAllUpkeep = form.isAllUpkeep ? 1 : 0;
      form.isHalfUpkeep = form.isHalfUpkeep ? 1 : 0;
      form.isMineralOil = form.isMineralOil ? 1 : 0;
      form.isDelicate = form.isDelicate ? 1 : 0;
      form.isStandard = form.isStandard ? 1 : 0;
      addOrUpdateCityConfig(form)
        .then((response) => {
          if (response.code == 200) {
            that.dialogClose();
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }
        })
    },

  },
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>